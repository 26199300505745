'use client';

import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useEffect } from 'react';

export default function HubSpotTrackingScript() {
    const pathname = usePathname();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            var _hsq = ((window as any)._hsq = (window as any)._hsq || []);
            const { search } = window.location;
            _hsq.push(['setPath', pathname + search]);
            _hsq.push(['trackPageView']);
        }
    }, [pathname]);

    return (
        <Script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer //NOTE_LT : removing this from HubSpot snippet ?
            strategy={'afterInteractive'}
            src="//js-eu1.hs-scripts.com/145180754.js"
        />
    );
}
