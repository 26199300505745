//NOTE_LT : Att, need to verify window is not undefined

export const retreiveAffiliationTrackingValues = () => {
    return window.localStorage.getItem('decodageFiscalLeadInfo');
};

export const saveAffiliationTrackingValuesToLocalStorage = () => {
    const obj = {
        isDecodageFiscalLead: true,
        decodageFiscalLeadFirstClickDate: new Date().setUTCHours(0, 0, 0, 0)
    };
    window.localStorage.setItem('decodageFiscalLeadInfo', JSON.stringify(obj));
};
