import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/public/icons/PL.svg");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/public/logos/stratt-logo-full.svg");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/components/Header/Header.module.sass");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/components/Home/ReviewScrollList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/components/Home/VerticalesScroll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/components/Navigation/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/components/Navigation/MobileNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/components/Navigation/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/hooks/AffiliationTrackingHook.tsx");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b8cfbe1f-a4dd-46c7-b482-431ef0532fd8/src/styles/styling.module.sass");
