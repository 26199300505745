// NOTE_LT : affiliation tracking mecanism

'use client';
import {
    retreiveAffiliationTrackingValues,
    saveAffiliationTrackingValuesToLocalStorage
} from '@/helpers/localStorageHandlers';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckIcon from '../../public/icons/check-circle.svg';

const Msg = () => {
    return (
        <div className="font-sans text-black">
            <div className="flex items-center gap-2 text-lg">
                <CheckIcon
                    className={'inline h-5 w-5 flex-shrink-0 text-[#07bc0c]'}
                />
                <p className="font-medium">Offre partenaire enregistrée !</p>
            </div>
            <p className="mt-1 leading-snug tracking-tight">
                OFFERT = 1 mois d'honoraires ou la création de votre structure
            </p>
        </div>
    );
};

const throwToast = () => {
    toast(<Msg />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        hideProgressBar: false,
        progress: undefined,
        theme: 'light'
    });
};

const AffiliationTrackingHook = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { search } = window.location;
            if (search.includes('utm_source=decodage-fiscal.fr')) {
                const decodageFiscalLeadInfo =
                    retreiveAffiliationTrackingValues();
                if (!decodageFiscalLeadInfo) {
                    saveAffiliationTrackingValuesToLocalStorage();
                    throwToast();
                }
            }
        }
    }, []);

    return (
        <>
            <ToastContainer />
            {/* <button onClick={throwToast}>Test toast</button> */}
        </> /* null */
    );
};

export default AffiliationTrackingHook;
